import React from 'react';
import { Col } from 'antd';
export function ReportStatus(props) {
  const reportState = props.name;

  if (reportState === 'Pending Verification') {
    return (
      <Col span={24}>
        Thank you for providing the information. We have received it and are currently verifying it.
        Please bear with us, and we will get back to you as soon as possible.
      </Col>
    );
  } else if (reportState === 'Verified') {
    return (
      <Col span={24}>
        The Report review has been passed, and we are contacting the brand and service provider for
        processing. Please wait patiently.
      </Col>
    );
  } else if (reportState === 'Resolved') {
    return (
      <Col span={24}>
        The website/phone number/Email number involved in this incident report has been stopped and
        the problem you reported has been resolved. Thank you for using Scams.Report.
      </Col>
    );
  } else if (reportState === 'Closed') {
    return (
      <Col span={24}>
        After review, it was found that the website/phone number/email address is legitimate and
        does not require further processing.
      </Col>
    );
  } else {
    return <Col span={24}>No corresponding status found.</Col>;
  }
}
