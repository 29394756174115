import React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
export function ReportTimeline(props) {
  const reportState = props.name;

  const childrenList = [];
  function text_children(item) {
    //`Pending verification 2024-09-17 10:35:09.`
    if (item === 'Pending Verification') {
      return `Pending verification.`;
    } else if (item === 'Verified') {
      return `Verified, contact service provider for processing.`;
    } else if (item === 'Resolved') {
      return `Resolved, Target URl is closed.`;
    } else if (item === 'Closed') {
      return `Closed, no risk found after manual verification.`;
    } else if (item === 'Open') {
      return `Report Open.`;
    }
  }

  let flag = 1;
  let colorText = '#adadad';
  const mode01 = item => {
    return {
      children: text_children(item),
      color: colorText,
    };
  };

  const mode02 = item => {
    return {
      dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
      children: text_children(item),
    };
  };

  const list = ['Closed', 'Resolved', 'Verified', 'Pending Verification', 'Open'];

  list.forEach(item => {
    if (reportState === item) {
      childrenList.push(mode02(item));
      if (1 === flag) {
        colorText = 'green';
        flag = 0;
      }
    } else {
      childrenList.push(mode01(item));
    }
  });

  return <Timeline mode="alternate" items={childrenList} />;
}
