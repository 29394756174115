import React, { useState, useEffect } from 'react';
import { Card, Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useSelector, useDispatch } from 'react-redux';
import { updateMessageByConversationER } from '@/services/chat.js';

const TypingWriter = ({ data, onComplete, speed = 0 }) => {
  const {
    text: { warning: text },
    isTyping,
  } = data;
  const [typedText, setTypedText] = useState('');
  const [index, setIndex] = useState(0);
  const { stopTyping, conversationExternalRef } = useSelector(state => state.chat);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stopTyping && isTyping) {
      setIndex(text.length + 1);
      dispatch({ type: 'chat/setStopTyping', payload: false });
      //调用接口更改message表字段typedText
      if (conversationExternalRef && typedText) {
        const content = JSON.stringify({ warning: typedText });
        updateMessageByConversationER({ conversationExternalRef, content }).then(res => {
          console.log('updateMessageByConversationER', res);
        });
      }
    }
    if (speed === 0) {
      setTypedText(text);
    } else {
      if (index < text.length) {
        const timeout = setTimeout(() => {
          setTypedText(prevText => prevText + text.charAt(index));
          setIndex(prevIndex => prevIndex + 1);
        }, speed);

        return () => clearTimeout(timeout); // 清理定时器
      } else if (onComplete) {
        // 打字完成后触发回调
        onComplete();
      }
    }
  }, [text, index, speed, onComplete, stopTyping]);

  return (
    <Card bordered={false}>
      <Typography.Text>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{typedText}</ReactMarkdown>
      </Typography.Text>
    </Card>
  );
};

export default TypingWriter;
