import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ChatInput from '@/components/ChatInput.js';
import MessageList from '@/components/MessageList.js';
import { Row, Col, message as AntdMessage } from 'antd';
import './NewChat.css';
import { fetchConversationId, ConversationChat, getConversationMessages } from '@/services/chat.js';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NewChat = () => {
  const { externalRef } = useParams();
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [currentTypingMessage, setCurrentTypingMessage] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { stopTyping, conversationExternalRef } = useSelector(state => state.chat);

  const location = useLocation();
  const paramMessage = location.state?.message;
  const currentAbortController = useRef(null);

  useEffect(() => {
    if (paramMessage) {
      addMessage(paramMessage);
    }
  }, [paramMessage]);
  useEffect(() => {
    const initializeConversation = async () => {
      if (!externalRef) {
        const response = await fetchConversationId();
        if (response) {
          const { externalRef: newExternalRef } = response;
          dispatch({ type: 'chat/setConversationExternalRef', payload: newExternalRef });
          navigate(`/chat/${newExternalRef}`);
        }
      } else {
        // 如果有 externalRef，则获取会话消息
        const response = await getConversationMessages(externalRef);
        const parsedMessages = response.map(messageItem => {
          let result = null;
          const { content, role, createdAt, reportGeneratesIdentification, isReport } = messageItem;
          if (role === 'assistant') {
            const parsedContent = JSON.parse(content);
            result = {
              text: parsedContent,
              role,
              createdAt,
              reportGeneratesIdentification,
              isReport,
            };
          } else {
            result = {
              text: content.message,
              role,
              createdAt,
              fileList: content.imageUrls
                ? content.imageUrls.map(url => ({ signedGetUrl: url }))
                : [],
              reportGeneratesIdentification: reportGeneratesIdentification,
              isReport: isReport,
            };
          }
          return result;
        });
        dispatch({ type: 'chat/setConversationExternalRef', payload: externalRef });
        setMessages(parsedMessages);
      }
    };
    initializeConversation();
  }, [externalRef, navigate]);

  useEffect(() => {
    if (stopTyping && currentAbortController.current) {
      currentAbortController.current.abort(); // 取消当前的请求
      currentAbortController.current = null; // 重置
    }
  }, [stopTyping]);

  const addMessage = async message => {
    const { fileList, text } = message;
    setMessages(prevMessages => [...prevMessages, { ...message }]);
    dispatch({ type: 'chat/setIsSending', payload: true });
    dispatch({ type: 'chat/setStopTyping', payload: false });
    const imageExternalRefs = [];
    const signedGetUrls = [];
    fileList.map(file => {
      imageExternalRefs.push(file.externalRef);
      signedGetUrls.push(file.signedGetUrl);
    });
    AntdMessage.info({
      content: t('new.chat.understanding.user.submission'),
      duration: 1,
    });
    setTimeout(() => {
      AntdMessage.info({
        content: t('new.chat.performing.initial.assessment'),
        duration: 1,
      });
    }, 2000);
    setTimeout(() => {
      AntdMessage.info(t('new.chat.gathering.cyber.threat.intelligence'));
    }, 4000);
    const abortController = new AbortController();
    const signal = abortController.signal;
    // 保存当前的 AbortController
    currentAbortController.current = abortController;
    const apiResponse = await ConversationChat(
      {
        message: text,
        imageExternalRefs,
        signedGetUrls,
        conversationExternalRef,
      },
      signal
    );

    console.log('apiResponse:', apiResponse);
    if (apiResponse) {
      let messageData;
      if (typeof apiResponse === 'string') {
        messageData = JSON.parse(apiResponse);
      } else {
        messageData = apiResponse;
      }
      const result = {
        text: messageData,
        role: 'assistant',
        createdAt: new Date().toLocaleString(),
        isTyping: true,
        reportGeneratesIdentification: messageData.reportGeneratesIdentification,
        isReport: messageData.isReport,
      };

      setCurrentTypingMessage(result);

      setTimeout(() => {
        setMessages(prevMessages => [...prevMessages, result]);
        setCurrentTypingMessage(null);
      }, 3000);
    } else {
      dispatch({ type: 'chat/setIsSending', payload: false });
    }
  };

  return (
    <div style={{ height: '100vh' }}>
      <Row justify="center" style={{ height: '70%', overflowY: 'auto' }}>
        <Col span={12}>
          <MessageList messages={messages} currentTypingMessage={currentTypingMessage} />
        </Col>
      </Row>

      <Row justify="center" style={{ height: '30%' }} align={'bottom'}>
        <Col span={12}>
          <ChatInput onSendMessage={addMessage} />
        </Col>
      </Row>
    </div>
  );
};

export default NewChat;
