import { createSlice } from '@reduxjs/toolkit';
const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    isSending: false,
    stopTyping: false,
    conversationExternalRef: '',
    reportGeneratesIdentification: false,
    isReport: false,
  },

  reducers: {
    setIsSending(state, action) {
      state.isSending = action.payload;
    },
    setStopTyping(state, action) {
      state.stopTyping = action.payload;
    },
    setConversationExternalRef(state, action) {
      state.conversationExternalRef = action.payload;
    },
    setReportGeneratesIdentification(state, action) {
      state.reportGeneratesIdentification = action.payload;
    },
    setIsReport(state, action) {
      state.isReport = action.payload;
    },
  },
});

export const {
  setIsSending,
  setStopTyping,
  setConversationExternalRef,
  setReportGeneratesIdentification,
  setIsReport,
} = chatSlice.actions;

export default chatSlice.reducer;
