import React, { useState } from 'react';
import { PaperClipOutlined, SendOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Upload,
  Input,
  Card,
  Row,
  Col,
  Image,
  Button,
  Badge,
  Space,
  Progress,
  message,
} from 'antd';
import { uploadAttachment } from '@/services/attachment.js';
import useFileUpload, { ERROR, DONE } from '@/hooks/useFileUpload.js';
import { useTranslation } from 'react-i18next';
import './ChatInput.css';
import { useSelector, useDispatch } from 'react-redux';
const { TextArea } = Input;

const ChatInput = props => {
  const { onSendMessage } = props;
  const [fileList, setFileList] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const { t } = useTranslation();
  const isSending = useSelector(state => state.chat.isSending);
  const reportGeneratesIdentification = useSelector(
    state => state.chat.reportGeneratesIdentification
  );
  const dispatch = useDispatch();

  const { handleFileUpload, beforeUpload, handleRemove, currentFileList, resetFileList } =
    useFileUpload({
      fileList,
      onChange: setFileList,
      setSubmitDisable,
      getSignedUrl: uploadAttachment,
      maxCount: 5,
    });

  const onTextAreaChange = e => {
    const value = e.target.value;

    if (value.length >= 2000) {
      message.error(
        <Card bordered={false} style={{ backgroundColor: '#fcbcbb' }}>
          {t('new.chat.contentLength.alert')}
        </Card>
      );
    }
    setTextAreaValue(value);
  };

  const onFinish = () => {
    if (!textAreaValue.trim() && fileList.length === 0) {
      message.error(t('new.chat.contentLength.validate'));
      return;
    }
    onSendMessage({
      text: textAreaValue,
      role: 'user',
      fileList,
      createdAt: new Date().toLocaleString(),
    });

    setTextAreaValue('');
    setFileList([]);
    resetFileList();
  };

  const stopGenerate = event => {
    event.stopPropagation();
    dispatch({ type: 'chat/setStopTyping', payload: true });
    dispatch({ type: 'chat/setIsSending', payload: false });
  };

  return (
    <Card className="chat-input-container">
      <Row justify="space-between">
        <Col span={24}>
          <Space>
            {fileList.map((file, index) => (
              <Badge
                key={file.uid}
                count={
                  <CloseOutlined
                    onClick={() => handleRemove(index)}
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      borderRadius: '50%',
                      fontSize: 16,
                      cursor: 'pointer',
                    }}
                  />
                }
              >
                <Image
                  key={file.signedGetUrl}
                  width={100}
                  height={100}
                  src={file.signedGetUrl}
                  alt="uploaded-image"
                />

                <Progress
                  percent={file.status === DONE ? 100 : file.percent}
                  status={file.status === ERROR ? 'exception' : undefined}
                  size="small"
                />
              </Badge>
            ))}
          </Space>

          <TextArea
            placeholder={t('new.chat.content.placeholder')}
            variant="borderless"
            autoSize={{ minRows: 1, maxRows: 8 }}
            showCount
            maxLength={2000}
            onChange={onTextAreaChange}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevents newline in the TextArea
                onFinish(); // Call onFinish, which contains validation
              }
            }}
            value={textAreaValue}
            disabled={reportGeneratesIdentification}
          />
        </Col>
      </Row>

      <Row justify="space-between" align={'bottom'}>
        <Col>
          <Upload.Dragger
            showUploadList={false}
            style={{ border: 0, backgroundColor: '#f5f5f5' }}
            maxCount={5}
            customRequest={handleFileUpload}
            beforeUpload={beforeUpload}
            disabled={reportGeneratesIdentification}
          >
            <PaperClipOutlined style={{ fontSize: 24 }} />
          </Upload.Dragger>
        </Col>
        <Col>
          <Button
            icon={isSending ? <LoadingOutlined onClick={e => stopGenerate(e)} /> : <SendOutlined />}
            style={{ fontSize: 24, border: 0, backgroundColor: '#ACAAAA' }}
            onClick={onFinish}
            disabled={submitDisable || reportGeneratesIdentification ? true : false}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ChatInput;
