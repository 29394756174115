import { useNavigate } from 'react-router-dom';
import { fetchConversationId } from '@/services/chat.js';

const useNavigateToMessagePage = () => {
  const navigate = useNavigate();

  const navigateToMessagePage = async message => {
    const response = await fetchConversationId();
    if (response) {
      const { externalRef } = response;
      navigate(`/chat/${externalRef}`, { state: { message } });
    }
  };

  return navigateToMessagePage;
};

export default useNavigateToMessagePage;
