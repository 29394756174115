import enUS from './en_US.json';
import zh from './zh_CN.json';

const resources = {
  'en-US': {
    translation: enUS,
  },
  'zh-CN': {
    translation: zh,
  },
  zh: {
    translation: zh,
  },
};

export default resources;
