import React from 'react';
import { Button, Image } from 'antd';
import logo from '@/assets/logo.png';
import './ContentSearch.css';

const HomePage = () => {
  return (
    <div>
      <div
        className="logo-img"
        style={{
          display: 'flex',
          justifyContent: 'center', // 水平居中
          alignItems: 'center', // 垂直居中
          height: '30vh', // 假设你想要全屏居中，可以使用视口高度
        }}
      >
        <Image width={100} src={logo} />
      </div>

      <p className="intro-text">
        Using Scams Report, you can identify{' '}
        <a
          href="https://www.cyber.gov.au/threats/types-threats/phishing"
          target="_blank"
          rel="noopener noreferrer"
        >
          phishing websites
        </a>{' '}
        and{' '}
        <a
          href="https://www.cyber.gov.au/threats/types-threats/scams"
          target="_blank"
          rel="noopener noreferrer"
        >
          scam emails
        </a>{' '}
        to ensure your Internet security.
      </p>

      {/* 按钮容器 */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '16px',
        }}
      >
        <Button className="textButton" type="dashed">
          Received a new email, but not sure if there is a security risk?
        </Button>
        <Button className="textButton" type="dashed">
          Planning to log into a new site but afraid you'll give away your information?
        </Button>
        <Button className="textButton" type="dashed">
          Retrieved two similar websites, not sure which one is the official website you want to
          visit?
        </Button>
      </div>
    </div>
  );
};

export default HomePage;
