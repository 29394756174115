import React from 'react';
import { Row, Col } from 'antd';
import ContentHome from './components/ContentSearch.js';
import ChatInput from '@/components/ChatInput.js';
import useNavigateToMessagePage from '@/hooks/useNavigateToMessagePage.js';

const ContentSearch = () => {
  const navigateToMessagePage = useNavigateToMessagePage();
  const addMessage = message => {
    navigateToMessagePage(message);
  };

  return (
    <Row justify="center" style={{ height: '100%' }} align="bottom">
      <Col>
        <ContentHome />
      </Col>
      <Col span={12} style={{ marginTop: 'auto' }}>
        <ChatInput onSendMessage={addMessage} />
      </Col>
    </Row>
  );
};

export default ContentSearch;
