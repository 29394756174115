import React, { useState, useEffect } from 'react';
import { Tabs, Button } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { reportDetails } from '@/services/report.js';
import TabPane from './components/TabPane.js';
import { useSearchParams } from 'react-router-dom';

const Report = () => {
  const [params] = useSearchParams();
  const [details, setDetails] = useState([]);

  const externalRef = params.get('id');
  async function getDetail() {
    await reportDetails(externalRef)
      .then(res => {
        const itemsTab = res.map(item => {
          // console.log('item~~~',item);
          return {
            key: item.report_id,
            label: item.report_name,
            children: <TabPane name={item} />,
          };
        });
        setDetails(itemsTab);
      })
      .catch(error => {
        console.error('Error getDetail:', error);
      });
  }

  useEffect(() => {
    // console.log('report得到了id~~~', externalRef);
    getDetail();
  }, [params]);

  // tabs
  // const onChange = key => {
  //   console.log(key);
  // };

  return (
    <Tabs
      defaultActiveKey="1"
      items={details}
      // onChange={onChange}
      type="card"
      tabBarExtraContent={
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          <Button
            shape="round"
            icon={<HistoryOutlined />}
            style={{ right: 30, position: 'absolute' }}
          >
            Return Conversation Log
          </Button>
        </div>
      }
    />
  );
};

export default Report;
