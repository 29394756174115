import React from 'react';
import TypingWriter from './TypingWriter.js';
import { List, Card, Row, Col, Image, Space } from 'antd';
import styles from './MessageList.css';
import RiskReport from './RiskReport.js';
import { useDispatch } from 'react-redux';

const MessageItem = props => {
  const { item } = props;
  const speed = item.isTyping ? 50 : 0;
  const dispatch = useDispatch();

  const handleCloseSendClick = () => {
    if (item.isTyping) {
      dispatch({ type: 'chat/setIsSending', payload: false });
    }
  };

  return (
    <List.Item
      style={{
        justifyContent: item.role === 'user' ? 'flex-end' : 'flex-start',
        padding: '8px 0',
      }}
    >
      <Card
        bodyStyle={{ padding: 0 }}
        style={{
          maxWidth: '65%',
          textAlign: item.role === 'user' ? 'right' : 'left',
          backgroundColor: item.role === 'user' ? '#f5f5f5' : '#ffffff',
        }}
      >
        <Row style={{ padding: '0 0 0 24px' }}>
          <Col>{item.createdAt}</Col>
        </Row>
        <Row>
          <Col>
            {item.fileList && (
              <Space>
                {item.fileList.map(file => (
                  <Image
                    key={file.signedGetUrl}
                    width={100}
                    height={100}
                    src={file.signedGetUrl}
                    alt="uploaded-image"
                  />
                ))}
              </Space>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {item.role === 'assistant' ? (
              item.text.input_type ? (
                <RiskReport
                  data={item.text}
                  onComplete={() => handleCloseSendClick()}
                  speed={speed}
                />
              ) : (
                <TypingWriter data={item} onComplete={() => handleCloseSendClick()} speed={speed} />
              )
            ) : (
              <Card style={{ backgroundColor: '#f5f5f5', border: 0 }}>{item.text}</Card>
            )}
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};

const MessageList = ({ messages, currentTypingMessage }) => {
  const dispatch = useDispatch();
  if (0 !== messages.length) {
    dispatch({
      type: 'chat/setReportGeneratesIdentification',
      payload: messages[messages.length - 1].reportGeneratesIdentification,
    });
    dispatch({ type: 'chat/setIsReport', payload: messages[messages.length - 1].isReport });
  } else {
    dispatch({
      type: 'chat/setReportGeneratesIdentification',
      payload: false,
    });
    dispatch({ type: 'chat/setIsReport', payload: false });
  }
  const dataSource = currentTypingMessage
    ? [...messages, { ...currentTypingMessage, isTyping: true }]
    : messages;

  return (
    <List split={false} dataSource={dataSource} renderItem={item => <MessageItem item={item} />} />
  );
};

export default MessageList;
