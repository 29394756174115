import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  TeamOutlined,
  PlusOutlined,
  UserOutlined,
  EllipsisOutlined,
  RightSquareOutlined,
  LeftSquareOutlined,
  MessageOutlined,
  ProfileOutlined,
  GoogleOutlined,
  FacebookOutlined,
  WindowsOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Image,
  Modal,
  Input,
  Row,
  Col,
  notification,
  Typography,
  Popover,
  Button,
  Checkbox,
} from 'antd';
import avatarDay from '@/assets/logo.png';
import loginWhite from '@/assets/login-white.png';
import { getReportListAPI, updateNameAPI } from '@/services/report.js';
import { getAllConversations } from '@/services/chat.js';
import './index.css';
import { config } from '@/config/index.js';

const { Content, Sider } = Layout;

const HomePage = () => {
  const navigate = useNavigate();
  const [newName, setNewName] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);

  const { Text } = Typography;

  const [renameId, setRenameId] = useState('');
  const [renameType, setRenameType] = useState('');
  const itemClick = (id, type, event) => {
    event.stopPropagation();
    setNewName('');
    setRenameId(id);
    setIsModalOpen(true);
    setRenameType(type);
  };

  const content = id => (
    <div>
      <p>
        <Button
          icon={<EditOutlined />}
          className="edit-button"
          size={'small'}
          onClick={() => itemClick(id, 'report')}
        >
          Rename
        </Button>
      </p>
      {/* <p>
        <Button danger icon={<DeleteOutlined />} className="edit-button" size={'small'}>
          Delete
        </Button>
      </p> */}
    </div>
  );

  async function getReportList() {
    await getReportListAPI()
      .then(res => {
        const itemsTab = res.map(item => {
          return {
            key: `/report?id=${item.externalRef}`,
            label: (
              <Row style={{ width: '100%' }}>
                <Col span={22}>
                  <Text ellipsis>{item.bridgeReportName}</Text>
                </Col>
                <Col span={2} style={{ paddingTop: '8px' }}>
                  <Popover content={content(item.externalRef)} trigger="hover">
                    <EllipsisOutlined />
                  </Popover>
                </Col>
              </Row>
            ),
          };
        });
        setReportList(itemsTab);
      })
      .catch(error => {
        console.error('Error ReportList:', error);
      });
  }
  const deleteConversation = (id, event) => {
    console.log('deleteConversation', id);
    event.stopPropagation();
  };

  const editConversation = id => (
    <div>
      <p>
        <Button
          icon={<EditOutlined />}
          className="edit-button"
          size={'small'}
          onClick={e => itemClick(id, 'conversation', e)}
        >
          Rename
        </Button>
      </p>
      <p>
        <Button
          danger
          icon={<DeleteOutlined />}
          className="edit-button"
          size={'small'}
          onClick={e => deleteConversation(id, e)}
        >
          Delete
        </Button>
      </p>
    </div>
  );

  const getAllConversation = async () => {
    await getAllConversations().then(data => {
      if (data) {
        const conversationItem = data.map(item => {
          return {
            key: `/chat/${item.externalRef}`,
            label: (
              <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col flex="1" style={{ overflow: 'hidden' }}>
                  <Text className="conversation-name">{item.name}</Text>
                </Col>
                <Col flex="24px">
                  <Popover content={editConversation(item.externalRef)} trigger="hover">
                    <EllipsisOutlined />
                  </Popover>
                </Col>
              </Row>
            ),
          };
        });
        setChatList(conversationItem);
      }
    });
  };

  useEffect(() => {
    getReportList();
    getAllConversation();
  }, []);

  useEffect(() => {
    // 更新菜单项
    const defaultMenuItems = [
      {
        key: '/new',
        icon: <PlusOutlined />,
        label: 'New chat',
      },
      {
        key: '/Conversation',
        icon: <MessageOutlined />,
        label: 'Conversation',
        children: chatList,
      },
      {
        key: '3',
        icon: <ProfileOutlined />,
        label: 'Report',
        children: reportList,
      },
    ];

    if (collapsed) {
      // 当折叠时，添加展开按钮到菜单项的第一个位置
      setMenuItems([
        {
          key: 'expand',
          icon: <RightSquareOutlined />,
          label: 'Expand',
          onClick: () => setCollapsed(false),
        },
        ...defaultMenuItems,
      ]);
    } else {
      // 当展开时，只展示默认菜单项
      setMenuItems(defaultMenuItems);
    }
  }, [collapsed, reportList, chatList]);

  const handleLoginModalOpen = () => {
    setIsLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  const handleLogin = provider => {
    window.location.href = `${config.API_BASE_URL}/auth/login/${provider}`;
    setIsLoginModalOpen(false);
  };

  const handleOk = async () => {
    if ('' === newName || newName.length < 1) {
      notification.open({
        message: 'Name modification prompt',
        description: 'The length of the name should be between 1 and 20.',
        duration: 3,
      });
      return;
    }

    const fromData = {
      bridgeReportName: newName,
      externalRef: renameId,
    };
    if (renameType === 'report') {
      updateNameAPI(fromData)
        .then(res => {
          console.log('newNameReq~~~', res);
          window.location.reload();
        })
        .catch(err => {
          console.log('newNameReqErr~~~', err);
        });
    }
    if (renameType === 'conversation') {
      await updateConversationNameAPI({ externalRef: renameId, name: newName });
    }

    handleCancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setRenameType('');
    setRenameId('');
  };

  // 路由跳转
  const onMenuClick = route => {
    if (route.key !== 'expand') {
      navigate(route.key);
    }
  };

  return (
    <Layout>
      <Sider
        theme="light"
        collapsible
        width={220}
        collapsed={collapsed}
        trigger={null}
        style={{
          height: '100vh',
          position: 'relative',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: collapsed ? 'center' : 'flex-start',
              padding: '20px 10px',
            }}
          >
            <Image width={50} src={avatarDay} preview={false} />
            {!collapsed && (
              <>
                <label className="intro-text" style={{ marginLeft: '10px' }}>
                  Scams.Report
                </label>
                <LeftSquareOutlined
                  className="sider-trigger"
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                  onClick={() => setCollapsed(true)}
                />
              </>
            )}
          </div>
          <Menu
            theme="light"
            defaultSelectedKeys={['/']}
            mode="inline"
            onClick={onMenuClick}
            items={menuItems}
          />
        </div>
        <div className="login-footer">
          <UserOutlined type="primary" block onClick={handleLoginModalOpen}>
            Login
          </UserOutlined>
        </div>
      </Sider>
      <Layout>
        <Content style={{ width: '100%', backgroundColor: '#ffffff' }}>
          <Outlet />
        </Content>
      </Layout>

      <Modal
        open={isLoginModalOpen}
        onCancel={handleLoginModalClose}
        footer={null}
        centered
        width={400}
      >
        <div style={{ textAlign: 'center' }}>
          <br />
          <h2>Scams Report</h2>
          <br />
          <h1>Welcome!</h1>
          <br />
          <Image src={loginWhite} preview={false} width={150} />
          <br />
          <br />
          <p>Prevent, Detect, Protect</p>
          <br />
          <Checkbox onChange={e => setIsTermsAgreed(e.target.checked)}>
            I agree to the
            <a href="https://www.cyberoo.ai/" target="_blank" rel="noopener noreferrer">
              {' '}
              terms and conditions
            </a>
            .
          </Checkbox>
          <br />
          <br />
          <Button
            icon={<GoogleOutlined />}
            type="primary"
            className="login-button google"
            onClick={() => handleLogin('google')}
            disabled={!isTermsAgreed}
          >
            Continue with Google
          </Button>
          <Button
            icon={<FacebookOutlined />}
            type="primary"
            className="login-button facebook"
            onClick={() => handleLogin('facebook')}
            disabled={!isTermsAgreed}
          >
            Continue with Facebook
          </Button>
          <Button
            icon={<WindowsOutlined />}
            type="primary"
            className="login-button microsoft"
            onClick={() => handleLogin('microsoft')}
            disabled={!isTermsAgreed}
          >
            Continue with Microsoft
          </Button>
        </div>
      </Modal>

      <Modal title="Rename" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input
          placeholder="A new name"
          value={newName}
          onChange={e => setNewName(e.target.value)}
          showCount
          maxLength={20}
          minLength={2}
        />
      </Modal>
    </Layout>
  );
};

export default HomePage;
