import React from 'react';
import Report from '@/page/Report/index.js';
import NewChat from '@/page/NewChat/index.js';
import HomePage from '@/page/Home/index.js';
import NotFound from '@/page/NotFound/index.js';
import ContentSearch from '@/page/Home/components/ContentSearch/index.js';
import { createBrowserRouter } from 'react-router-dom';

// router
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    children: [
      {
        path: '/report',
        element: <Report />,
      },
      {
        index: true,
        element: <ContentSearch />,
      },
      {
        path: '/new',
        element: <NewChat />,
      },
      {
        path: '/chat/:externalRef',
        element: <NewChat />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
export default router;
