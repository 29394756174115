import { notification } from 'antd';
import React from 'react';
import axios from 'axios';
import { uploadToS3 } from '@/services/attachment.js';

export const UPLOADING = 'uploading';
export const PROCESSING = 'processing';
export const ERROR = 'error';
export const DONE = 'done';

const fileToObject = file => {
  return {
    ...file,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    path: file.path,
    name: file.name,
    size: file.size,
    type: file.type,
    uid: file.uid,
  };
};

const getFileItemIndex = (file, fileList) => {
  const matchKey = file.uid !== undefined ? 'uid' : 'externalRef';
  return fileList.findIndex(item => item[matchKey] === file[matchKey]);
};

const useFileUpload = ({
  fileList,
  onChange,
  setSubmitDisable,
  getSignedUrl,
  onProcess,
  accept,
  maxCount = 5,
  notificationMessage = '最多只能上传 {maxCount} 个文件',
}) => {
  if (typeof getSignedUrl !== 'function') {
    throw new TypeError('getSignedUrl must be a function');
  }

  const ref = React.useRef([...fileList]);
  const currentFileList = ref.current;

  React.useEffect(() => {
    return () => {
      currentFileList.forEach(({ source }) => {
        if (source) {
          source.cancel();
        }
      });
    };
  }, [currentFileList]);

  const resetFileList = () => {
    currentFileList.length = 0; // 清空引用数组
    onChange([]); // 通知外部同步清空
  };

  const onUploadProgress = React.useCallback(
    foundIndex => progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      currentFileList[foundIndex].percent = percentCompleted;
      onChange([...currentFileList]);
    },
    [currentFileList, onChange]
  );

  const handleFileUpload = React.useCallback(
    async option => {
      setSubmitDisable(true);
      const foundIndex = getFileItemIndex(option.file, currentFileList);

      if (foundIndex < 0) {
        return;
      }
      const { CancelToken } = axios;
      currentFileList[foundIndex].source = CancelToken.source();

      try {
        const fileResponse = await getSignedUrl(option.file);
        console.log('fileResponse:', fileResponse, 'foundIndex:', foundIndex);

        await uploadToS3(
          fileResponse.signedPutUrl,
          option.file,
          onUploadProgress(foundIndex),
          currentFileList[foundIndex].source.token
        );

        let processedResponse;
        if (typeof onProcess === 'function') {
          currentFileList[foundIndex].status = PROCESSING;
          onChange([...currentFileList]);
          processedResponse = await onProcess(fileResponse.externalRef, fileResponse.name);
        }

        currentFileList[foundIndex] = {
          ...fileResponse,
          ...processedResponse,
          status: DONE,
        };
        onChange([...currentFileList]);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        currentFileList[foundIndex].status = ERROR;
      } finally {
        setSubmitDisable(false);
      }
    },
    [onChange, setSubmitDisable, currentFileList, onUploadProgress, getSignedUrl, onProcess]
  );

  const beforeUpload = async file => {
    if (fileList.length >= maxCount) {
      notification.error({ message: notificationMessage.replace('{maxCount}', maxCount) });
      setSubmitDisable(false);
      return;
    }
    if (accept && accept !== file.type) {
      notification.error({ message: 'component.fileUpload.format.error' });
      setSubmitDisable(false);
      return;
    }
    const fileToBeUploaded = fileToObject(file);

    currentFileList.push({ ...fileToBeUploaded, status: UPLOADING });
    onChange([...currentFileList]);
  };

  const handleRemove = index => {
    const { source } = currentFileList[index];
    if (source) {
      source.cancel();
    }

    currentFileList.splice(index, 1);
    onChange([...currentFileList]);
  };

  return { beforeUpload, handleRemove, handleFileUpload, currentFileList, resetFileList };
};

export default useFileUpload;
