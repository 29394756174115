import React from 'react';
import { Layout, Image } from 'antd';
import notFound from '@/assets/404.png';

const NotFound = () => {
  return (
    <Layout>
      <Image src={notFound} />
    </Layout>
  );
};

export default NotFound;
