import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Progress, Card, Row, Col, Tooltip, Image, Button, Space, Modal } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import LikeIcon from '@/assets/chatIcon/like.svg';
import unLikeIcon from '@/assets/chatIcon/unLike.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './RiskReport.css';
import { addReportAPI } from '@/services/report.js';

const { Title, Paragraph, Text } = Typography;

const RiskReport = ({ data, onComplete, speed = 0 }) => {
  const navigate = useNavigate();
  const [currentText, setCurrentText] = useState('');
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const isStopTyping = useSelector(state => state.chat.stopTyping);
  const isStopTypingRef = useRef(isStopTyping);
  const isReport = useSelector(state => state.chat.isReport);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fields = [
    { label: 'Involved Organization', content: data.involved_organization },
    { label: 'Actionable Insights', content: data.cta },
    { label: 'Possible Incident Type', content: data.possible_incident_type },
    { label: 'Explanation', content: data.explanation },
    { label: 'Advice', content: data.advice },
  ].filter(field => field.content);

  // 打字机动画
  useEffect(() => {
    isStopTypingRef.current = isStopTyping;
  }, [isStopTyping]);

  // 打字机动画
  useEffect(() => {
    let wordIndex = 0;

    const typeText = () => {
      const field = fields[currentFieldIndex];
      const words = field.content.split(' ');

      const interval = setInterval(() => {
        if (isStopTypingRef.current) {
          clearInterval(interval); // 停止打字机动画
          dispatch({ type: 'chat/setStopTyping', payload: false }); // 重置 stopTyping 状态
          return; // 结束当前的定时器
        }

        setCurrentText(prev => prev + (prev ? ' ' : '') + words[wordIndex]);
        wordIndex++;

        if (wordIndex >= words.length) {
          clearInterval(interval);
          setTimeout(() => {
            setCurrentFieldIndex(prev => prev + 1);
            setCurrentText('');
          }, 500);
        }
      }, speed);

      return () => clearInterval(interval);
    };

    if (speed === 0) {
      setCurrentText(allContent);
      // setIsTypingComplete(true);
    } else if (currentFieldIndex < fields.length) {
      typeText();
    } else {
      setIsTypingComplete(true);
      onComplete();
    }
  }, [currentFieldIndex, speed, dispatch]);

  const allContent = fields.map(field => `${field.label}:\n${field.content}`).join('\n\n');

  const handleLikeClick = (type, field) => {
    // 处理点赞逻辑
  };

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  async function addReport() {
    const conversationId = data.conversationId;
    const cta = data.finalCtas;

    const report = {
      chatId: conversationId,
      cta: cta,
    };

    await addReportAPI(report)
      .then(res => {
        //禁用report按钮
        dispatch({ type: 'chat/setIsReport', payload: true });
        navigate(`/report/?id=${res}`);
      })
      .catch(error => {
        console.error('Error addReport:', error);
      });
  }

  const handleOk = () => {
    addReport();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const reportClick = () => {
    setIsModalOpen(true);
  };

  return (
    <Card bordered={false}>
      <Row align="middle" style={{ width: '100%' }}>
        <Col flex="auto">
          <Tooltip
            color="white"
            title={
              <Space>
                <Text>
                  {t('new.chat.RiskReport')}:{data.risk_score}
                </Text>
                <Image
                  preview={false}
                  src={LikeIcon}
                  alt="like"
                  width={20}
                  onClick={() => handleLikeClick('like', 'risk')}
                />
                <Image
                  preview={false}
                  src={unLikeIcon}
                  alt="unlike"
                  width={20}
                  onClick={() => handleLikeClick('unlike', 'risk')}
                />
              </Space>
            }
          >
            <Progress
              percent={(data.risk_score / 1000) * 100}
              status="active"
              showInfo={false}
              strokeColor={{
                '0%': '#61C26E',
                '25%': '#E9E138',
                '75%': '#F79122',
                '100%': '#FF0000',
              }}
              className="risk-progress"
            />
          </Tooltip>
        </Col>
        {/* <Col>
              <Text style={{ color: '#FF0000', fontWeight: 'bold' }}>High</Text>
            </Col> */}
      </Row>
      <Row>
        <Col>
          <Typography>
            {fields.slice(0, currentFieldIndex + 1).map((field, index) => (
              <Paragraph key={index}>
                <Title level={5}>{field.label}:</Title>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {index === currentFieldIndex && !isTypingComplete ? currentText : field.content}
                </ReactMarkdown>
              </Paragraph>
            ))}
          </Typography>
        </Col>
      </Row>

      <Row justify={'space-between'} style={{ padding: '20px 0 20px 0' }}>
        <Col span={2} align={'center'}>
          <Text copyable={{ text: allContent }}></Text>
        </Col>
        <Col span={4} align={'center'}>
          <Button
            ghost
            style={{ backgroundColor: '#f7bd40' }}
            onClick={() => reportClick()}
            disabled={isReport}
          >
            Report
          </Button>
        </Col>
      </Row>

      <>
        <Modal
          title={<div style={{ textAlign: 'center' }}>Disclaimer</div>}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          cancelText="Cancel"
          cancelButtonProps={{ shape: 'round', style: { float: 'left', marginLeft: '100px' } }}
          okText="Accept"
          okButtonProps={{ shape: 'round', style: { marginRight: '100px' } }}
        >
          <Row justify="center" style={{ textAlign: 'center' }}>
            <Col>
              This Chatbot is not a financlal nor legal advisor, and can sometimes confidently make
              mistakes; always exercise with caution.
            </Col>
          </Row>
        </Modal>
      </>
    </Card>
  );
};

export default RiskReport;
