import { configureStore } from '@reduxjs/toolkit';
import chatSlice from '@/store/modules/chatSlice.js';

const store = configureStore({
  reducer: {
    chat: chatSlice,
  },
});

export default store;
